import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Head from '../components/head';
import Layout from '../components/layout';

const Character = ({
  name,
  imagePath,
  bio,
  title,
  fullTitle,
  position,
  navNext,
  navPrevious,
}) => {

  useEffect(() => {
    loadBGImage();
  });

  const loadBGImage = () => {
    const imageUrl = require(`../images/characters/${imagePath}`);

    let imgContainer = document.querySelector(".character-image");

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloaderImg = document.createElement("img");
    preloaderImg.src = imageUrl;
    preloaderImg.alt = `${name} Illustration`
    preloaderImg.classList.add(`${position}`)

    preloaderImg.addEventListener('load', (event) => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloaderImg)
      preloaderImg = null;
    });
  }

  const PAGE = name;
  const baseSiteUrl = 'https://authorseanallen.com';

  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta
          name="description"
          content={`Character: ${fullTitle},
          Creator: Sean Allen,
          Books: The D-Evolution,
          Illustrator: Matt Dixon`} />
        <link rel="canonical" href={`${baseSiteUrl}/${PAGE}`} />
      </Helmet>
      <h2 className="character-name">{title}</h2>
      <section className="character-dossier hero light">
        <div className="content">
          <div className="nav-previous">
            <Link className="prev-character" to={`/${navPrevious}`}>&laquo;</Link>
          </div>
          <div
            className="character-bio right-col"
            dangerouslySetInnerHTML={bio}
          />
          <div className={`character-image ${name} left-col loading`}>
            {/* //* Images inserted dynamically  */}
          </div>
          <div className="nav-next">
            <Link to={`/${navNext}`}>&raquo;</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Character;